.account-title-name {
  margin-top: 10px;
}

.profile-with-name {
  display: flex;
  flex-direction: column;
  width: 20%;
  align-items: center;
  gap: 10px;
  margin-top: 70px !important;
}

.Company-main-account-profile {
  width: 90%;
  flex-wrap: wrap;
  height: 95vh;
  margin: 0 auto !important;
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.card-components-main {
  margin-left: 5%;
  width: 70% ;
  padding: 10px;
  flex-wrap: wrap;
  margin-top: 70px !important;
}

.single-container-component-main {
  background-color: rgba(255, 226, 14, 0.7);
  width: 40%;
  border-radius: 5px;
  margin-top: 5%;
  padding: 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.icon-color-style {
  display: flex;
  gap: 10px;
  font-size: 32px;
  color: black;
  margin-bottom: 10px;
}
.name-icon-same-container {
  display: flex;
  justify-content: space-between;
  font-size: 22px;
}

.card-components-all-main {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

.link-button-edit-account {
  display: flex;
  justify-content: flex-end;
}

/* UserProfile.css */
.location-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.location-form-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.location-form-close-button {
  display: flex;
  justify-content: flex-end;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  color: black;
}

.location-form-inputs {
  border-radius: 5px;
}

.location-form-labels {
  font-size: 18px !important;
}

.manage-loc-delete-icon {
  cursor: pointer;
}

.manage-loc-delete-icon:hover {
  fill: #dc3545;
}
.new-loc-form-container form {
  width: 400px;
}


/*media query*/
@media (max-width: 508px) {
  .Company-main-account-profile {
    height: 100%;
    width: 80%;
    margin: 0 auto !important;
    overflow-x: hidden !important;
  }

  .card-components-main {
    margin-left: 0%;
    padding: 10px;
    flex-wrap: wrap;
    margin-top: 70px !important;
  }

  .card-components-all-main {
    display: flex;
    justify-content: center;
    width: 80%;
  }

  .card-components-main h1 {
    margin-top: 20px;
  }

  .userprofile-two-sentences {
    margin-left: 20px !important;
  }

  .single-container-component-main {
    background-color: rgba(255, 226, 14, 0.7);
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  /* UserProfile.css */
  .location-form-overlay {
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .location-form-modal {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .location-form-close-button {
    display: flex;
    justify-content: flex-end;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    color: black;
  }

  .location-form-inputs {
    border-radius: 5px;
    width: 100% !important;
  }

  .location-form-labels {
    font-size: 18px !important;
  }

  .new-loc-form-container form {
    width: 300px;
  }
}

@media (max-width: 1400px) {
  .profile-with-name {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-items: center;
    gap: 10px;
    margin-top: 70px !important;
    margin: 0 auto !important;
  }

  .Company-main-account-profile {
    /* background-color: red; */
    height: 100%;
    width: 100%;
    margin: 0 auto !important;
    overflow-x: hidden !important;
    display: flex;
    justify-content: center;
    /* margin-left: 0px; */
  }

  .card-components-main {
    margin-left: 0%;
    padding: 10px;
    flex-wrap: wrap;
    margin-top: 0px !important;
    margin-bottom: 30px;
  }

  .card-components-main h1,
  .card-components-main h5 {
    margin-left: 40px;
  }

  .card-components-all-main {
    display: flex;
    justify-content: center;
    width: 90%;
    margin: 0 auto !important;
  }

  .single-container-component-main {
    background-color: rgba(255, 226, 14, 0.7);
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}
@media (max-width: 768px) {
  .card-components-main {
    width: 90% !important;
  }
  .Company-main-account-profile {
    margin-top: 50px !important;
  }
}

