.admin-all-forms-inputs {
  border-radius: 5px !important;
  width: 50% !important;
}

.admin-all-forms-containter {
  margin-left: 50px !important;
  margin-bottom: 20px !important;
}

.ullllllll {
  margin: 0 !important;
}
