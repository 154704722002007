.pending{
    color: orange !important;
}

.accepted, .delivered{
    color: greenyellow !important;
}

.canceled{
    color: red !important;
}