.div-for-image-shop{
    width: 140px; 
    height: 120px;
    border-radius: 15px;
    margin-bottom: 20px;

}

.div-for-image-shop img{
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.single-cart-product-shopping-main{
    display: flex;
    width: 90%;
    gap: 20px;
    align-self: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.374);
    margin-left: 20px;
    margin-top: 20px;

    

}

.span-price-style{
    font-size: 40px;
}

.all-about-descriptions{
    gap: 0 !important;
    margin: 0 !important;
    width: 100%;

}
.counters-counter{
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    margin: 0 !important;
    height:  30px !important;
    width: 10px !important;
    background-color: rgb(230, 229, 229);
}
.counters-counter p{
    font-size: 35px !important;
    height: 40px;
}

.mask-add-counter{
    align-items: center !important;
    background-color: rgba(224, 222, 222, 0.429);
    justify-content: center !important;
    padding: 0 !important;
    border-radius: 25px;
    height: 53%;
}

.quantity-name-shop{
    margin-top: 10px;

}

.price-with-quantitiy{
    justify-content: space-between !important;

}

.title-delete-icon{
    justify-content: space-between !important;
}

.icon-delete-shop-cart{
    font-size: 29px;
    color: rgba(0, 0, 0, 0.566);
}


@media (max-width: 768px){
    .div-for-image-shop{
        height: 120px;
        width: 170px;
    }

}