.card-collab-component-main{
    width: 150px !important;
    height: 150px !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 15px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
   margin:0 auto !important;

}
.card-collab-component-main img{
    border-radius: inherit;
}

/* [1] The container */
.img-hover-zoom {
    height: 300px; /* [1.1] Set it as per your need */
    overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  }
  
  /* [2] Transition property for smooth transformation of images */
  .img-hover-zoom img {
    transition: transform .5s ease;
  }
  
  /* [3] Finally, transforming the image when container gets hovered */
  .img-hover-zoom:hover img {
    transform: scale(1.5);
  }

  @media (max-width: 768px){
    .card-collab-component-main{
      width: 150px !important;
      align-items: center !important;
    justify-content: center !important;
    
    }

  }