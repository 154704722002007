.category-single-component-main {
    width: 190px;
    height: 190px;
    position: relative;
    overflow: hidden;
    margin: 0 auto !important;

  }
  
  .dark-overlay {
    background-color: rgba(0, 0, 0, 0.604);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  @media (min-width: 768px){
  .category-single-component-main:hover .dark-overlay {
    opacity: 1;
  }
  
  .dark-overlay img {
 
    object-fit: cover;
  }
  
  .overlay-text {
    color: white;
    font-size: 40px !important;
    font-weight: bold;
  }
}

@media (max-width:768px){
  .category-single-component-main .dark-overlay {
    opacity: 1;
  }
  
  .dark-overlay img {
 
    object-fit: cover;
  }
  
  .overlay-text {
    color: white;
    font-size: 40px !important;
    font-weight: bold;
  }

  .category-single-component-main {
    width: 150px;
    height: 150px;
    position: relative;
    
    overflow: hidden;
  }
  .image-for-category-single{
    width: 150px;
    height: 150px;
  }
}