.single-product-component-card{
    width: 250px !important;
    height: 430px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.162) !important;

}

@media (max-width: 1226px){
    .single-product-component-card{
        width: 130px !important;
        height: 240px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.162) !important;
    margin-bottom: 30px !important;
    

    }
    .button-for-add-to-card-button{
        width: 80px !important;
        height: 25px;
        /* border: 2px solid red !important; */
        font-size: 10px !important;
        
    }
    .image-product-main-con{
        width: 130px;
        height: 130px;
    }
    .title-text-for-cardshop{
        font-size: 14px !important;
    }
    .card-bod-shop{
        padding: 5px !important;
    }
}

.color-circle-main{
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
    margin-right: 10px;
}
