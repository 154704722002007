.Home-main-component{
    width:100% !important;
    height: 80vh !important;
    align-items: center;
    justify-content: center;
    gap:15% !important;
    flex-wrap: wrap;

}

.Home-main-component-child{
    justify-content: center !important;
    align-items: center !important;

}
.Home-main-component-child{
    margin-bottom: 150px !important;
}
@keyframes typingAnimation {
    from {
      width: 0;
    }
  }
  
  .typing-text {
    overflow: hidden;
    border-right: 0.15em solid orange; /* Adjust the border properties as needed */
    white-space: nowrap;
    margin: 0;
    font-size: 1.5em; /* Adjust the font size as needed */
    animation: typingAnimation 2s steps(40, end) infinite;
  }
  .home-hero-img{
    margin-left: -600px !important;
  }

  @media (max-width: 1000px) {
    .home-hero-img{
       display:none
    }
    
    .animation-tyoe-text{
        margin-left: 60px !important;
        font-size: 3em !important;
        width: 60% !important;
    }

  }

  @media (max-width: 768px) {
    .Home-main-component{
      width: 90% !important;
    }
 
  }

  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120vh;
    object-fit: cover;
    z-index: -1;
  }

  .discover-more-cta-button{
    display: none !important;
  }

  @media (max-width: 768px){
    .discover-more-cta-button{
      display: flex !important;
      justify-self: center !important;
      align-self: center !important;
      margin-top: 550px !important;
      margin-left: 30px !important;
    }
  }